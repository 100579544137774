import { useCallback, useMemo } from 'react';

import { usePathname } from 'next/navigation';
import { useRouter } from 'next/router';

import { MeApi } from '../../adapters/api';
import { useGlobalMe } from '../../hooks/use-global-me';
import { to } from '../../utils/async';
import { getCurrentTabFromPath } from '../../utils/nav-bar';
import { toastError } from '../../utils/notification';
import { DEFAULT_SIDEBAR_WIDTH } from './constants';

export const useSidebarWidth = () => {
  const { me, setNewMeQueryData, isLoading } = useGlobalMe();

  const meSidebar = me?.preferences?.sidebar;

  const router = useRouter();

  const { pathname } = router;

  const currentPageTab = getCurrentTabFromPath(pathname);

  const width = useMemo(() => {
    const sidebars = {
      dashboard: meSidebar?.dashboard_page_width,
      connect: meSidebar?.connection_page_width,
      transformation: meSidebar?.transformation_page_width,
    };
    if (currentPageTab === 'subscription') return DEFAULT_SIDEBAR_WIDTH;
    return sidebars[currentPageTab] || DEFAULT_SIDEBAR_WIDTH;
  }, [currentPageTab, meSidebar]);

  const serverWidth = useMemo(() => {
    if (isLoading) return undefined;

    const sidebars = {
      dashboard: meSidebar?.dashboard_page_width,
      connect: meSidebar?.connection_page_width,
      transformation: meSidebar?.transformation_page_width,
    };

    if (currentPageTab === 'subscription') return DEFAULT_SIDEBAR_WIDTH;

    return sidebars[currentPageTab] || DEFAULT_SIDEBAR_WIDTH;
  }, [currentPageTab, meSidebar, isLoading]);

  const setWidthClient = useCallback(
    (w: number) => {
      const newSideBar = {
        ...meSidebar,
        ...(currentPageTab === 'dashboard' && { dashboard_page_width: w }),
        ...(currentPageTab === 'connect' && { connection_page_width: w }),
        ...(currentPageTab === 'transformation' && {
          transformation_page_width: w,
        }),
      };
      setNewMeQueryData({
        preferences: {
          ...me?.preferences,
          sidebar: newSideBar,
        },
      });
    },
    [currentPageTab, me?.preferences, meSidebar, setNewMeQueryData],
  );

  const updateOnServer = useCallback(async () => {
    if (!me) return;
    if (!me.preferences) return;
    const [_, err, unknownErr] = await to(() =>
      MeApi.onBrowser()
        .updateUserPreference({
          ...me.preferences!,
        })
        .then((data) => () => {
          if (data) setNewMeQueryData(data);
        }),
    );
    if (err || unknownErr) {
      toastError(
        'An unknown error has occurred. Please contact the administrator.',
      );
    }
  }, [me, setNewMeQueryData]);

  const optimisticUpdateWidthPreference = useCallback(
    async (w: number) => {
      const newSideBar = {
        ...meSidebar,
        ...(currentPageTab === 'dashboard' && { dashboard_page_width: w }),
        ...(currentPageTab === 'connect' && { connection_page_width: w }),
        ...(currentPageTab === 'transformation' && {
          transformation_page_width: w,
        }),
      };
      const nextPreference = {
        ...me?.preferences,
        sidebar: newSideBar,
      };

      setNewMeQueryData({
        preferences: nextPreference,
      });

      const [_, err, unknownErr] = await to(() =>
        MeApi.onBrowser()
          .updateUserPreference(nextPreference)
          .then((data) => () => {
            if (data) setNewMeQueryData(data);
          }),
      );

      if (err || unknownErr) {
        toastError(
          'An unknown error has occurred. Please contact the administrator.',
        );
      }
    },
    [currentPageTab, me?.preferences, meSidebar, setNewMeQueryData],
  );

  return {
    width,
    setWidthClient,
    updateOnServer,
    serverWidth,
    optimisticUpdateWidthPreference,
  };
};

export const useSidebarWidthApp = () => {
  'use client';
  const { me, setNewMeQueryData, isLoading } = useGlobalMe();

  const meSidebar = me?.preferences?.sidebar;

  const pathname = usePathname();

  const currentPageTab = getCurrentTabFromPath(pathname!);

  const width = useMemo(() => {
    const sidebars = {
      dashboard: meSidebar?.dashboard_page_width,
      connect: meSidebar?.connection_page_width,
      transformation: meSidebar?.transformation_page_width,
    };
    if (currentPageTab === 'subscription') return DEFAULT_SIDEBAR_WIDTH;
    return sidebars[currentPageTab] || DEFAULT_SIDEBAR_WIDTH;
  }, [currentPageTab, meSidebar]);

  const serverWidth = useMemo(() => {
    if (isLoading) return undefined;

    const sidebars = {
      dashboard: meSidebar?.dashboard_page_width,
      connect: meSidebar?.connection_page_width,
      transformation: meSidebar?.transformation_page_width,
    };
    if (currentPageTab === 'subscription') return DEFAULT_SIDEBAR_WIDTH;
    return sidebars[currentPageTab] || DEFAULT_SIDEBAR_WIDTH;
  }, [currentPageTab, meSidebar, isLoading]);

  const setWidthClient = useCallback(
    (w: number) => {
      const newSideBar = {
        ...meSidebar,
        ...(currentPageTab === 'dashboard' && { dashboard_page_width: w }),
        ...(currentPageTab === 'connect' && { connection_page_width: w }),
        ...(currentPageTab === 'transformation' && {
          transformation_page_width: w,
        }),
      };
      setNewMeQueryData({
        preferences: {
          ...me?.preferences,
          sidebar: newSideBar,
        },
      });
    },
    [currentPageTab, me?.preferences, meSidebar, setNewMeQueryData],
  );

  const updateOnServer = useCallback(async () => {
    if (!me) return;
    if (!me.preferences) return;
    const [_, err, unknownErr] = await to(() =>
      MeApi.onBrowser()
        .updateUserPreference({
          ...me.preferences!,
        })
        .then((data) => () => {
          if (data) setNewMeQueryData(data);
        }),
    );
    if (err || unknownErr) {
      toastError(
        'An unknown error has occurred. Please contact the administrator.',
      );
    }
  }, [me, setNewMeQueryData]);

  const optimisticUpdateWidthPreference = useCallback(
    async (w: number) => {
      const newSideBar = {
        ...meSidebar,
        ...(currentPageTab === 'dashboard' && { dashboard_page_width: w }),
        ...(currentPageTab === 'connect' && { connection_page_width: w }),
        ...(currentPageTab === 'transformation' && {
          transformation_page_width: w,
        }),
      };
      const nextPreference = {
        ...me?.preferences,
        sidebar: newSideBar,
      };

      setNewMeQueryData({
        preferences: nextPreference,
      });

      const [_, err, unknownErr] = await to(() =>
        MeApi.onBrowser()
          .updateUserPreference(nextPreference)
          .then((data) => () => {
            if (data) setNewMeQueryData(data);
          }),
      );

      if (err || unknownErr) {
        toastError(
          'An unknown error has occurred. Please contact the administrator.',
        );
      }
    },
    [currentPageTab, me?.preferences, meSidebar, setNewMeQueryData],
  );

  return {
    width,
    setWidthClient,
    updateOnServer,
    serverWidth,
    optimisticUpdateWidthPreference,
  };
};
