import { z } from 'zod';

import { DAYS_OF_WEEK } from '../constants';

const zHourlySchedule = z.object({
  type: z.literal('HOURLY'),
  interval_hours: z.number(),
});

export type HourlySchedule = z.infer<typeof zHourlySchedule>;

const zDailySchedule = z.object({
  type: z.literal('DAILY'),
});

export type DailySchedule = z.infer<typeof zDailySchedule>;

const zWeeklySchedule = z.object({
  type: z.literal('WEEKLY'),
  days_of_weeks: z.array(z.enum(DAYS_OF_WEEK)),
});

export type WeeklySchedule = z.infer<typeof zWeeklySchedule>;

const zMonthlySchedule = z.object({
  type: z.literal('MONTHLY'),
  order_numbers: z.array(z.number()),
  days_of_weeks: z.array(z.enum(DAYS_OF_WEEK)),
});

export type MonthlySchedule = z.infer<typeof zMonthlySchedule>;

const zSubscriptionSchedule = z.intersection(
  z.object({
    start_time: z.string(),
    timezone: z.string(),
  }),
  z.union([zHourlySchedule, zDailySchedule, zWeeklySchedule, zMonthlySchedule]),
);

export type SubscriptionSchedule = z.infer<typeof zSubscriptionSchedule>;

const zEmailSubscriptionOptions = z.object({
  type: z.literal('EMAIL'),
  email_list: z.array(z.string()),
});

export type EmailSubscriptionOptions = z.infer<
  typeof zEmailSubscriptionOptions
>;

const zSlackSubscriptionOptions = z.object({
  type: z.literal('SLACK'),
  slack_webhook_urls: z.array(z.string()),
});

export type SlackSubscriptionOptions = z.infer<
  typeof zSlackSubscriptionOptions
>;

const zSubscriptionCommunicationConfig = z.union([
  zEmailSubscriptionOptions,
  zSlackSubscriptionOptions,
]);

export type SubscriptionCommunicationConfig = z.infer<
  typeof zSubscriptionCommunicationConfig
>;

export const zSubscription = z.object({
  id: z.string(),
  dashboard_id: z.string(),
  schedule: zSubscriptionSchedule,
  filtered_in_widget_report_view_ids: z.array(z.string()).nullish(),
  subscribe_all_views: z.boolean().nullish(),
  message: z.string().nullish(),
  communication_config: zSubscriptionCommunicationConfig,
  creator_member_id: z.string(),
});

export const zSubscriptionPayload = zSubscription.omit({
  id: true,
  creator_member_id: true,
});

export type SubscriptionPayload = z.infer<typeof zSubscriptionPayload>;

export type EmailSubscriptionPayload = z.infer<
  typeof zEmailSubscriptionOptions
>;
export type SlackSubscriptionPayload = z.infer<
  typeof zSlackSubscriptionOptions
>;

export type Subscription = z.infer<typeof zSubscription>;
