import type { SxProps, Theme } from '@mui/material/styles';

import { CIGRO_SCROLL_BAR_MIXINS } from '@/constants/mixins';

export const DND_DROP_ZONE_ID_FAVORITE_DASHBOARD = 'favorite-dashboard-zone';
export const DND_DROP_ZONE_ID_NORMAL_DASHBOARD = 'normal-dashboard-zone';
export const DND_DRAG_ITEM_ID_DASHBOARD = 'dashboard-item';
export const DND_DRAG_ITEM_ID_FAVORITE_DASHBOARD = 'dashboard-favorite-item';

export const SHARED_ITEM_SX = {
  position: 'relative',
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  textDecoration: 'none',
  overflow: 'hidden',
  ':hover': {
    bgcolor: 'neutral.6',
  },
  ':focus': {
    outline: 'none',
  },
  ':focus-visible': {
    boxShadow: (t: Theme) => `0 0 0 2px ${t.palette.primary[30]}`,
    outline: 'none',
    bgcolor: 'neutral.6',
    zIndex: 10,
  },
  ':active': {
    bgcolor: 'neutral.5',
  },
};

export const SIDEBAR_INNER_LIST_SX = {
  p: '8px',
  height: '100%',
  width: '100%',
  maxHeight: '100%',
  overflow: 'hidden',
  ':hover': {
    overflowY: 'auto',
  },
};

export const SIDEBAR_LABELS = {
  liked_order: {
    label: 'favorite-dashboard-heading',
    iconName: 'ic_side_menu_24_favorite',
  },
  order: {
    label: 'dashboard-heading',
    iconName: 'dashboard',
  },
} as const;

export const SIDEBAR_TRANSFORMATION_ITEM_ACTIONS_CLASSNAME =
  'CigroTransformationItem';

export const DEFAULT_SIDEBAR_WIDTH = 280;

export const SIDEBAR_TRANSFORMATION_ITEM_ACTIONS_START_ICON_CLASSNAME =
  'CigroTransformationItem__start-icon';

export const DEFAULT_DASHBOARD_NAME = 'New Dashboard Name';
