import type { QueryClientConfig } from '@tanstack/react-query';

import React, { useRef } from 'react';

import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';

// NOTE: Import Korean, English languages for dayjs
import 'dayjs/locale/en';
import 'dayjs/locale/ko';

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { CacheProvider } from '@emotion/react';
import { CssBaseline, ThemeProvider as MuiThemeProvider } from '@mui/material';
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import axios from 'axios';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import utc from 'dayjs/plugin/utc';
import weekDay from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { appWithTranslation } from 'next-i18next';
import { SnackbarOrigin, SnackbarProvider } from 'notistack';

import '@/global.css';
// NOTE: global css for app & ErrorBoundary
import { registerApiErrorParserResponseInterceptor } from '@/adapters/api/axios/utils';
import { RbacPermissionProvider } from '@/adapters/rbac';
import { ApiErrorUtils } from '@/api-error';
import appConfig from '@/constants/configs';
import { EnvironmentContextProvider } from '@/contexts/EnvironmentContext';
import { NavigationContextProvider } from '@/contexts/NavigationContext';
import createEmotionCache from '@/create-emotion-cache';
import useStructuredClonePolyfill from '@/hooks/use-structured-clone-polyfill';
import useViewportWidthPolyfill from '@/hooks/use-viewport-width-polyfill';
import { AppErrorBoundary } from '@/initializers/AppErrorBoundary';
import { AppGlobalStyles } from '@/initializers/AppGlobalStyles';
// import CalendlyInit from '@/initializers/CalendlyInit';
import { GoogleAnalyticsScripts } from '@/initializers/GoogleAnalyticsScripts';
import NextAuthSessionProvider from '@/initializers/NextAuthSessionProvider';
import { SetupUserRbacPermissions } from '@/initializers/rbac';
import SurvicateInit from '@/initializers/SurvicateInit';
import ZendeskInit from '@/initializers/ZendeskInit';
import { appTheme } from '@/theme';
import { MyAppProps } from '@/types';
import LocaleHandler from '@components/LocaleHandler';
import { AppSnackBar } from '@components/SnackBar';

import i18nextConfig from '../next-i18next.config';
import { GlobalPromptStoreProvider } from '../src/feature-report/ai-prompt/GlobalPromptStoreProvider';
// import CalendlyInit from '../src/initializers/CalendlyInit';
import LemonSqueezyInit from '../src/initializers/LemonSqueezyInit';

ModuleRegistry.registerModules([ClientSideRowModelModule, CsvExportModule]);

dayjs.extend(quarterOfYear);
dayjs.extend(weekDay);
dayjs.extend(weekOfYear);
dayjs.extend(utc);
dayjs.extend(customParseFormat);

const NextAuthGuard = dynamic(() => import('@components/NextAuthGuard'), {
  ssr: false,
});

const DataDogRumInit = dynamic(
  () => import('@/initializers/data-dog').then((mod) => mod.DataDogRumInit),
  {
    ssr: false,
  },
);

// const CalendlyButtonProvider = dynamic(
//   () => import('@/initializers/CalendlyButtonProvider'),
//   {
//     ssr: false,
//   },
// );

const CIGRO_TITLE = appConfig.DEPLOY_TYPE;
const ENABLE_SCRIPT = appConfig.ENABLE_SCRIPT;
const SMARTLOOK_KEY = appConfig.SMARTLOOK_KEY;
const SURVICATE_KEY = appConfig.SURVICATE_KEY;
const ZENDESK_KEY = appConfig.ZENDESK_KEY;
export const CSS_VAR_SIDE_NAVBAR_WIDTH = '--cg-side-navbar-width';

// NOTE: Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

const DEFAULT_QUERY_STALE_TIME_IN_MS = 1000 * 20; // NOTE: 20 seconds
const DEFAULT_QUERY_RETRIES = 0; // NOTE: 1 time
const DEFAULT_QUERY_RETRY_DELAY_IN_MS = 1000; // NOTE: 1 seconds
const DEFAULT_SNACKBAR_TIMEOUT_IN_MS = 1000 * 5; // NOTE: 5 seconds
const HTTP_UNAUTHORIZED_CODE = 401;
const HTTP_FORBIDDEN_CODE = 403;
const SNACKBAR_COMPONENTS = { custom: AppSnackBar };
const SNACKBAR_ANCHOR_ORIGIN: SnackbarOrigin = {
  horizontal: 'center',
  vertical: 'top',
};

const QUERY_CLIENT_CONFIG: QueryClientConfig = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: DEFAULT_QUERY_STALE_TIME_IN_MS,
      retry: (failureCount, error) => {
        if (
          ApiErrorUtils.isApiError(error) &&
          error.statusCode === HTTP_UNAUTHORIZED_CODE
        ) {
          return failureCount < 3; // 2 times
        }
        return failureCount < 2; // 1 time
      },
      retryDelay: DEFAULT_QUERY_RETRY_DELAY_IN_MS,
      useErrorBoundary: (error: any) => {
        // NOTE: throw error into nearest error boundary
        return (
          ApiErrorUtils.isApiError(error) &&
          (error.statusCode === HTTP_FORBIDDEN_CODE ||
            error.statusCode === HTTP_UNAUTHORIZED_CODE)
        );
      },
    },
  },
};

// NOTE: INITIALIZERS
// CLIENT-SIDE ONLY
if (typeof window !== 'undefined') {
  // NOTE: setup default axios base URL
  const baseURL = process.env.API;
  axios.defaults.baseURL = baseURL;
  registerApiErrorParserResponseInterceptor(axios);
}

const NOOP_COMPONENT = (page: React.ReactElement) => page;

function MyApp(props: MyAppProps) {
  const {
    Component,
    session,
    emotionCache = clientSideEmotionCache,
    pageProps,
  } = props;

  const queryClient = useRef(new QueryClient(QUERY_CLIENT_CONFIG)).current;

  // NOTE: polyfill(s)
  useViewportWidthPolyfill();
  useStructuredClonePolyfill();

  // NOTE: processing order: auth > layout

  // NOTE: get page layout
  const getLayout = Component.getLayout ?? NOOP_COMPONENT;

  const ComponentWithLayout = getLayout(<Component {...pageProps} />);

  // NOTE: page config
  let PageComponent = ComponentWithLayout;

  // NOTE: if the page has auth config -> apply auth guard
  if (Component.authConfig)
    PageComponent = (
      <NextAuthGuard {...Component.authConfig}>
        {ComponentWithLayout}
      </NextAuthGuard>
    );

  return (
    <NextAuthSessionProvider session={session}>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <CacheProvider value={emotionCache}>
            <Head>
              <title>{CIGRO_TITLE}</title>
              <meta
                name="viewport"
                content="width=device-width, viewport-fit=cover, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
              />
            </Head>

            <MuiThemeProvider theme={appTheme}>
              <SnackbarProvider
                maxSnack={3}
                anchorOrigin={SNACKBAR_ANCHOR_ORIGIN}
                Components={SNACKBAR_COMPONENTS}
                autoHideDuration={DEFAULT_SNACKBAR_TIMEOUT_IN_MS}
                preventDuplicate
                //
              >
                <CssBaseline />

                {/* <ZendeskButtonProvider> */}
                {/* <CalendlyButtonProvider /> */}
                {/* </ZendeskButtonProvider> */}

                <AppErrorBoundary>
                  {/*RBAC*/}
                  <RbacPermissionProvider>
                    {/* INITIALIZERS~~ */}
                    <LocaleHandler />
                    <SetupUserRbacPermissions />
                    {/* <SmartLookInit
                      enable={ENABLE_SCRIPT}
                      smartLookKey={SMARTLOOK_KEY}
                    /> */}
                    <SurvicateInit enable survicateKey={SURVICATE_KEY} />
                    <ZendeskInit enable zendeskKey={ZENDESK_KEY} />
                    {/* <CalendlyInit enable /> */}
                    <LemonSqueezyInit enable />
                    <DataDogRumInit />
                    {/* <CustomCssScriptLoader /> */}
                    <GoogleAnalyticsScripts />
                    {/* ~~INITIALIZERS */}

                    <GlobalPromptStoreProvider>
                      <EnvironmentContextProvider>
                        <NavigationContextProvider>
                          <AppGlobalStyles />
                          {PageComponent}
                        </NavigationContextProvider>
                      </EnvironmentContextProvider>
                    </GlobalPromptStoreProvider>
                  </RbacPermissionProvider>
                </AppErrorBoundary>
              </SnackbarProvider>
            </MuiThemeProvider>
          </CacheProvider>

          <ReactQueryDevtools />
        </Hydrate>
      </QueryClientProvider>
    </NextAuthSessionProvider>
  );
}

/**
 * NOTE: This is a workaround to set the locale from the query params
 * @param args
 * @returns
 */
function App(...args: any[]) {
  const router = args[0].router as ReturnType<typeof useRouter>;
  if (typeof window !== 'undefined' && router?.query?.lang) {
    args[0] = {
      ...args[0],
      router: { ...args[0].router, locale: router.query.lang },
    };
    // @ts-ignore
    return appWithTranslation(MyApp, i18nextConfig)(...args);
  }
  // @ts-ignore
  return appWithTranslation(MyApp, i18nextConfig)(...args);
}

export default App;
