import { z } from 'zod';

import { makeSafeSchema } from '@/utils/data';

import {
  zAggregateItem,
  zCommonChartConfig,
  zGrouping,
  zGroupItem,
  zSortItem,
} from './common';
import { zCompatibleCommonConfig } from './compatibility';

export const zColumnChartType = z.enum(['BAR_CHART', 'LINE_CHART']);
export type ColumnChartType = z.infer<typeof zColumnChartType>;

export const zColumnYAxisSide = z.enum(['RIGHT', 'LEFT']);
export type ColumnYAxisSide = z.infer<typeof zColumnYAxisSide>;

export const zComboChartSubAggregateItem = zAggregateItem.extend({
  chart_type: zColumnChartType.optional(),
  y_axis_side: zColumnYAxisSide.optional(),
  color_by: zGroupItem.nullish(),
});
export type ComboChartSubAggregateItem = z.infer<
  typeof zComboChartSubAggregateItem
>;

const zComboChartGrouping = zGrouping.merge(
  z.object({
    sub_aggregates: z.array(zComboChartSubAggregateItem),
  }),
);

export const zComboChartConfig = zCommonChartConfig.extend({
  view_type: z.literal('BAR_AND_LINE_COMBINED_CHART'),
  grouping: makeSafeSchema(zComboChartGrouping.optional(), {
    columns: [],
    sub_aggregates: [],
  }),
  sort: makeSafeSchema(z.array(zSortItem), []),
});

export const zComboChartFullConfig =
  zCompatibleCommonConfig.merge(zComboChartConfig);

export type ComboChartFullConfig = z.infer<typeof zComboChartFullConfig>;

export const zComboChartRenderingConfig = zComboChartFullConfig;

export type ComboChartConfig = z.infer<typeof zComboChartConfig>;
