import type { AxiosError, AxiosInstance } from 'axios';

import { isAxiosError } from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { getSession } from 'next-auth/react';
import pMemoize from 'p-memoize';

import { ApiError, ApiErrorUtils } from '@/api-error';
import { zApiServerErrorSchema } from '@/models/common';
import { saveToken, TokenStorageObject } from '@/utils/client-side-token';

const HTTP_UNAUTHORIZED_CODE = 401;

type CleanUpFn = () => void;

/**
 * Inject access token into request authorization header (bearer)
 * @param instance
 * @param tokenGetter
 */
export function registerTokenHeaderRequestInterceptor(
  instance: AxiosInstance,
  tokenGetter: () => Promise<TokenStorageObject>,
): CleanUpFn {
  const id = instance.interceptors.request.use(async function (request) {
    const { jwt } = await tokenGetter();
    if (!jwt) return request;
    request.headers['Authorization'] = `Bearer ${jwt}`;
    return request;
  });
  return () => {
    instance.interceptors.request.eject(id);
  };
}

/**
 * Response interceptor, try to parse errors into application errors!
 * @param instance
 */
export function registerApiErrorParserResponseInterceptor(
  instance: AxiosInstance,
) {
  const id = instance.interceptors.response.use(
    function (response) {
      // response.data = JSONBigInt.parse(response.data);
      return response;
    },
    async function (error) {
      const errData =
        error?.config?.responseType === 'blob'
          ? JSON.parse(await error.response.data.text())
          : error?.response?.data;

      const parseResult = await zApiServerErrorSchema.safeParseAsync(errData);

      // NOTE: throw like default behavior
      if (!parseResult.success) {
        return Promise.reject(error);
      }

      const { data } = parseResult;

      // NOTE: init ApiError then throw away!

      const apiErr = new ApiError(error, data, error.response?.status);

      return Promise.reject(apiErr);
    },
  );

  return () => {
    instance.interceptors.response.eject(id);
  };
}

/**
 * Refresh token then retry 401 requests in batching manner!
 */
export function registerRefreshTokenOn401ResponseInterceptor(
  instance: AxiosInstance,
  refreshAuthFn: (failedJwt: string) => Promise<TokenStorageObject>,
) {
  createAuthRefreshInterceptor(
    instance,
    async (failedRequest) => {
      if (isAxiosError(failedRequest)) {
        const failedJwt = failedRequest.config?.headers?.[
          'Authorization'
        ] as string;

        const tokenObj = await refreshAuthFn(failedJwt);

        if (!tokenObj) return;

        const { jwt, access_token_expires_at } = tokenObj;

        if (!jwt) return;

        // NOTE: save new token
        saveToken(jwt, access_token_expires_at ?? '');

        if (!failedRequest?.response?.config) return;

        // NOTE: setup retry config
        failedRequest.response.config.headers['Authorization'] =
          'Bearer ' + jwt;

        // console.log(
        //   'failedRequest.response.config',
        //   failedRequest.response.config,
        // );

        return Promise.resolve();
      } else if (ApiErrorUtils.isApiError(failedRequest)) {
        console.log('api error on retry', failedRequest.statusCode);
      }
    },
    {
      // NOTE: this only work is it is AxiosError
      shouldRefresh(error: AxiosError): boolean {
        return error?.response?.status === HTTP_UNAUTHORIZED_CODE;
      },
      //
      onRetry: (requestConfig) => {
        // console.log('requestConfig', requestConfig);
        return requestConfig;
      },
      // NOTE: this not work
      // pauseInstanceWhileRefreshing: true,
    },
  );
}

/**
 * Memoized version of the `getSession`
 */
export const getSessionMemoized = pMemoize(async (key: string) => {
  // console.log('getSessionMemoized called', key.slice(-6));
  return await getSession();
});
