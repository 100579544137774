export enum AppRoutes {
  INDEX = '/d', // NOTE: main redirect page, please do NOT remove it
  TEAMS = '/d',
  REPORT = '/report',
  SIGNIN = '/auth/signin',
  SIGNUP = '/auth/signup',
  OTP = '/auth/otp',
  CREATE_TEAM = '/create-team',
  CREATE_PROFILE = '/create-profile',
  ERROR = '/auth/error',
  PUBLIC_DASHBOARD = '/d/site',
  SUBSCRIPTION_UNSUBSCRIBE = '/subscription/_unsubscribe_email',
}

export enum ROUTE {
  TRANSFORMATION = 'transformation',
  CONNECTION = 'connect',
  DATASET = 'dataset',
  DATA_MODEL = 'data-model',
  SUBSCRIPTION = 'subscription',
}

function getLocale() {
  if (typeof window === 'undefined') return '';
  // NOTE: Assume the locale is already prefixed by middleware
  return window.location.pathname.split('/')[1];
}

function normalizePathname(pathname: string) {
  // Remove duplicate slashes
  return pathname.replaceAll(/\/+/g, '/');
}

export function getTeamRoute(teamId: string) {
  return normalizePathname(
    '/' + [getLocale(), AppRoutes.TEAMS, teamId].join('/'),
  );
}

export function getDashboardRoute(teamId: string, dashboardId: string) {
  return [getTeamRoute(teamId), dashboardId].join('/');
}

export function getTransformationRoute(teamId: string) {
  return [getTeamRoute(teamId), ROUTE.TRANSFORMATION].join('/');
}

export function getSubscriptionRoute(teamId: string) {
  return [getTeamRoute(teamId), ROUTE.SUBSCRIPTION].join('/');
}

export enum TransformationDataModelPanel {
  PREVIEW = 'preview',
  BUILD = 'build',
  COLUMNS = 'columns',
  RELATED = 'related',
  DETAILS = 'details',
  MANAGE_EXPORT = 'manage-export',
}

export function getTransformationGraphRoute(teamId: string) {
  return [getTransformationRoute(teamId), 'graph'].join('/');
}

export function getTransformationDataModelPanel(
  teamId: string,
  datasetId: string,
  dataModelId: string,
  panel: TransformationDataModelPanel,
) {
  return [
    getTransformationCollectionRoute(teamId, datasetId),
    ROUTE.DATA_MODEL,
    dataModelId,
    panel,
  ].join('/');
}

export function getTransformationDatasetDetailRoute(
  teamId: string,
  datasetId: string,
) {
  return [getTransformationRoute(teamId), ROUTE.DATASET, datasetId].join('/');
}

export function getTransformationDataModelDetailRoute(
  teamId: string,
  datasetId: string,
  dataModelId: string,
  panel: TransformationDataModelPanel = TransformationDataModelPanel.COLUMNS,
) {
  return [
    getTransformationDatasetDetailRoute(teamId, datasetId),
    dataModelId,
    panel,
  ].join('/');
}

export function getWidgetRoute(
  teamId: string,
  dashboardId: string,
  widgetId: string,
  isShowSettings: boolean | 'sort' = false,
) {
  const url = [getDashboardRoute(teamId, dashboardId), widgetId].join('/');
  const params = new URLSearchParams({
    show_settings: isShowSettings.toString(),
  });
  return `${url}?${params.toString()}`;
}

export function getHashDashboardRoute(
  teamId: string,
  dashboardId: string,
  widgetId: string,
) {
  const url = getDashboardRoute(teamId, dashboardId);
  return `${url}?hash=${widgetId}`;
}

export function getTeamInvitationRoute(teamId: string) {
  return [AppRoutes.TEAMS, teamId, 'invitations'].join('/');
}

export function getConnectionRoute(teamId: string, connectionId?: string) {
  return [getTeamRoute(teamId), ROUTE.CONNECTION, connectionId].join('/');
}

export function getCSVConnectionRoute(teamId: string, connectionId: string) {
  return [getTeamRoute(teamId), ROUTE.CONNECTION, connectionId, 'csv'].join(
    '/',
  );
}

export function getConnectionStatusRoute(teamId: string, connectionId: string) {
  return [getTeamRoute(teamId), ROUTE.CONNECTION, connectionId].join('/');
}

export function getConnectionSchemaRoute(teamId: string, connectionId: string) {
  return [getTeamRoute(teamId), ROUTE.CONNECTION, connectionId, 'schema'].join(
    '/',
  );
}

export function getConnectionRouteSelect(teamId: string) {
  if (!teamId) return AppRoutes.INDEX;
  return [getTeamRoute(teamId), ROUTE.CONNECTION, 'select'].join('/');
}

export function getConnectionSetupRouteWithReAuth(
  teamId: string,
  connectionId: string,
) {
  return [
    getTeamRoute(teamId),
    ROUTE.CONNECTION,
    connectionId,
    'setup?re-auth=true',
  ].join('/');
}

export function getConnectionSetupRoute(teamId: string, connectionId: string) {
  return [getTeamRoute(teamId), ROUTE.CONNECTION, connectionId, 'setup'].join(
    '/',
  );
}

export function getTransformationCollectionRoute(
  teamId: string,
  collectionId: string,
) {
  return getTransformationDetailRoute(teamId, collectionId);
}

export function getTransformationDetailRoute(
  teamId: string,
  collectionId: string,
) {
  return [getTeamRoute(teamId), ROUTE.TRANSFORMATION, collectionId].join('/');
}

export function getPublicDashboardRoute(
  dashboardName: string,
  dashboardId: string,
) {
  const params = [
    encodeURIComponent(dashboardName.substring(0, 20)),
    dashboardId,
  ];

  const name = params.join('-');

  return [AppRoutes.PUBLIC_DASHBOARD, name].join('/');
}
