'use client';

import { getSession } from 'next-auth/react';
import invariant from 'tiny-invariant';
import z from 'zod';

import { ApiError } from '@/api-error';

const zTokenObj = z.object({
  jwt: z.string().nullable(),
  access_token_expires_at: z.string().nullable(),
  refreshToken: z.function(
    z.tuple([]),
    z.promise(
      z
        .object({
          jwt: z.string(),
          access_token_expires_at: z.string(),
        })
        .nullable(),
    ),
  ),
});

export type TokenStorageObject = z.infer<typeof zTokenObj>;

///

type HasTokenEventListener = (payload: TokenStorageObject) => void;

class HasTokenEvent {
  private listeners: HasTokenEventListener[] = [];
  constructor() {}
  subscribe(fn: HasTokenEventListener) {
    this.listeners.push(fn);
  }
  unsubscribe(fn: HasTokenEventListener) {
    this.listeners = this.listeners.filter(function (item) {
      if (item !== fn) {
        return item;
      }
    });
  }
  fire(o: TokenStorageObject) {
    this.listeners.forEach(function (item) {
      item(o);
    });
  }
}

type RefreshTokenFn = () => Promise<{
  jwt: string;
  access_token_expires_at: string;
} | null>;

const hasTokenEvent = new HasTokenEvent();

export const defaultRefreshTokenFn: RefreshTokenFn = async () => {
  const session = await getSession();
  if (!session) return null;
  return {
    jwt: session.access_token,
    access_token_expires_at: session.access_token_expires_at,
  };
};

let $jwt: string | null = null;
let $accessTokenExpiresAt: string | null = null;
let $refreshToken: RefreshTokenFn | null = null;

// TODO: should remove all tokens when sign out

export function saveToken(
  accessToken: string,
  accessTokenExpiresAt: string,
  refreshTokenFn = defaultRefreshTokenFn,
) {
  if (typeof window === 'undefined') {
    invariant(false, 'client-side only module.');
  }

  $jwt = accessToken;
  $accessTokenExpiresAt = accessTokenExpiresAt;
  $refreshToken = refreshTokenFn ?? defaultRefreshTokenFn;

  // NOTE: notify listeners
  hasTokenEvent.fire({
    jwt: accessToken,
    access_token_expires_at: accessTokenExpiresAt,
    refreshToken: refreshTokenFn ?? defaultRefreshTokenFn,
  });
}

export function getToken(): TokenStorageObject | null {
  if (typeof window === 'undefined') {
    invariant(false, 'client-side only.');
  }

  return {
    jwt: $jwt,
    access_token_expires_at: $accessTokenExpiresAt,
    refreshToken: $refreshToken ?? defaultRefreshTokenFn,
  };
}

export function fireTokenEvent() {
  // NOTE: notify listeners
  hasTokenEvent.fire({
    jwt: $jwt,
    access_token_expires_at: $accessTokenExpiresAt,
    refreshToken: $refreshToken ?? defaultRefreshTokenFn,
  });
}

const TIMEOUT_MS = 1000 * 20; // 20s
const TimeoutError = new ApiError(
  'Token wait timeout',
  {
    detail: '[client-side-error] No valid token received within timeout period',
  },
  401,
);

/**
 * Wait for the token to be set, throw an error if timeout (10 seconds)
 * @returns
 */
export function waitForToken(): Promise<TokenStorageObject> {
  return new Promise(async (resolve, reject) => {
    // NOTE: if the current token is valid, return immediately
    const currentToken = getToken();

    if (currentToken && isTokenValid(currentToken)) {
      return resolve(currentToken);
    }

    // NOTE: try to refresh the token if `refreshToken` is set
    if ($refreshToken) {
      try {
        const newToken = await $refreshToken();
        if (newToken) {
          return resolve({ ...newToken, refreshToken: $refreshToken });
        }
      } catch (error) {
        // NOTE: ignore the error, and wait for the token to be set
      }
    }

    // NOTE: else, wait for the token to be set
    // Define event listener
    const whenTokenAvailableListener: HasTokenEventListener = (token) => {
      cleanUp();
      resolve(token);
    };

    // Create timeout error handler
    const timeoutId = setTimeout(() => {
      hasTokenEvent.unsubscribe(whenTokenAvailableListener);
      reject(TimeoutError);
    }, TIMEOUT_MS);

    const cleanUp = () => {
      clearTimeout(timeoutId); // Clear timeout if token is received
      hasTokenEvent.unsubscribe(whenTokenAvailableListener);
    };

    hasTokenEvent.subscribe(whenTokenAvailableListener);
  });
}

const BUFFER_TIME_MS = 1000 * 60; // 1 minute

export function isTokenValid(_token: TokenStorageObject | null): boolean {
  if (!_token) return false;
  if (!_token.jwt) return false;
  // NOTE: only check if the token has an expiration date
  if (!_token.access_token_expires_at) return true;

  // PROBLEM: we have two formats of expiration timestamp: '1733049956977' and '2024-10-31T15:00:00.000Z'
  const expiresAtEpoch = Number(_token.access_token_expires_at);

  // HANDLE FORMAT: '2024-10-31T15:00:00.000Z'
  if (Number.isNaN(expiresAtEpoch)) {
    const expiresAt = Date.parse(_token.access_token_expires_at);
    return expiresAt - Date.now() > BUFFER_TIME_MS;
  }

  // HANDLE FORMAT: '1733049956977'
  const localeExpiresAtEpoch = new Date(expiresAtEpoch).getTime();

  // Add some buffer time (e.g., 1 minute) to prevent edge cases
  return localeExpiresAtEpoch - Date.now() > BUFFER_TIME_MS;
}
