'use client';

import { useEffect } from 'react';

import { userLangKey } from '@app/_internal/utils/session-key';

import appConfig from '@/constants/configs';
import { useGlobalMe } from '@/hooks/use-global-me';
import { getCookie, setCookie } from '@/utils/client-cookie';

export function LocaleHandler__APP() {
  return <LocaleHandler />;
}

export default function LocaleHandler() {
  const { me } = useGlobalMe();
  const userLangCookieName = userLangKey(me?.email);

  // NOTE: sync locale with user preferred language
  useEffect(() => {
    const meLanguage = me?.language;
    if (meLanguage) {
      setCookie(appConfig.USER_PREFERRED_LANGUAGE_COOKIE_NAME, meLanguage);
      if (userLangCookieName) {
        const userLang = getCookie(userLangCookieName);
        if (!userLang) {
          setCookie(userLangCookieName, meLanguage);
        }
      }
    }
  }, [userLangCookieName, me?.language]);

  return null;
}
