'use client';

import { useEffect } from 'react';

import { useCountDown } from 'ahooks';
import dayjs from 'dayjs';

import axiosBigNumberClient from '@/adapters/api/axios/axios-big-number-client';
import axiosHttpClient from '@/adapters/api/axios/axios-http-client';

const SOON_SECS = 5;

function expiresInSeconds(expiresAtUtcDateString: string): number {
  const nowUtc = dayjs(new Date().toUTCString());
  const expirationUtc = dayjs(expiresAtUtcDateString);
  return expirationUtc.diff(nowUtc, 'seconds');
}

const AxiosBearerSynchronizer = ({
  jwt,
  expiresAt,
  onExpire,
}: {
  jwt: string;
  expiresAt: string;
  onExpire: () => void;
}) => {
  const secondsLeft = expiresInSeconds(expiresAt) - SOON_SECS;
  const [, formattedRes] = useCountDown({
    leftTime: secondsLeft * 1000,
  });
  // const { minutes, seconds } = formattedRes;
  // console.log(`${minutes} minutes ${seconds} seconds`);
  useEffect(() => {
    if (secondsLeft <= 0) {
      onExpire();
      return;
    }
  }, [onExpire, secondsLeft]);

  // NOTE: set the bearer token
  axiosHttpClient.defaults.headers.common.Authorization = `Bearer ${jwt}`;
  axiosBigNumberClient.defaults.headers.common.Authorization = `Bearer ${jwt}`;

  return null;
};

export { AxiosBearerSynchronizer };
