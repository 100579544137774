'use client';

import type { Session } from 'next-auth';
import type { PropsWithChildren } from 'react';

import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { SessionProvider, useSession } from 'next-auth/react';

import { AxiosBearerSynchronizer } from './AxiosBearerSynchronizer';

function expiresInSeconds(expiresAtUtcDateString: string): number {
  const nowUtc = dayjs(new Date().toUTCString());
  const expirationUtc = dayjs(expiresAtUtcDateString);
  return expirationUtc.diff(nowUtc, 'seconds');
}

const SOON_SECS = 5;

const NextAuthJwt = ({
  onExpireAfter,
}: {
  onExpireAfter: (interval: number) => void;
}) => {
  const { data: session, status: sessionStatus } = useSession();

  const secondsLeft = session?.access_token_expires_at
    ? expiresInSeconds(session.access_token_expires_at) - SOON_SECS
    : null;

  useEffect(() => {
    if (
      sessionStatus === 'loading' ||
      session?.error === 'RefreshAccessTokenError'
    ) {
      return;
    }

    // NOTE: polling when session has been expired, til it's refreshed
    if (secondsLeft == null || secondsLeft < 0) {
      onExpireAfter(1);
      return;
    }

    onExpireAfter(secondsLeft);
  }, [onExpireAfter, secondsLeft, session?.error, sessionStatus]);

  if (sessionStatus === 'loading') return null;

  if (sessionStatus === 'unauthenticated') return null;

  // NOTE: only skip if session cannot be refreshed
  if (session?.error === 'RefreshAccessTokenError') return null;

  // NOTE: still allow anonymous access
  if (!session?.access_token || !session?.access_token_expires_at) {
    return null;
  }

  return (
    <AxiosBearerSynchronizer
      jwt={session.access_token}
      expiresAt={session.access_token_expires_at}
      onExpire={() => {}}
    />
  );
};

const NextAuthSessionProvider: React.FC<
  PropsWithChildren<{
    session?: Session;
  }>
> = ({ session, children }) => {
  const [refetchInterval, setRefetchInterval] = useState(0);
  return (
    <SessionProvider
      session={session}
      refetchOnWindowFocus={false}
      refetchInterval={refetchInterval}
      //
    >
      <NextAuthJwt onExpireAfter={setRefetchInterval} />
      {children}
    </SessionProvider>
  );
};

export default NextAuthSessionProvider;
