'use client';

import type { TypographyOptions } from '@mui/material/styles/createTypography';

import { createTheme } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';

import {
  backgroundColors,
  chartColors,
  neutralColors,
  neutralV2Colors,
  pointColors,
  primaryColors,
  semanticColors,
  textColors,
} from './color-tokens';
import InputTextFieldTheme from './components/input-text-field.theme';
import PopoverTheme from './components/popover.theme';

// export const FALLBACK_FONT_FAMILIES =
//   'system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"';

const FALLBACK_FONT_FAMILIES = 'system-ui';

export const PRETENDARD_FONT_FAMILY = [
  'Pretendard Variable',
  'Pretendard',
  FALLBACK_FONT_FAMILIES,
].join(',');

export const CSS_VAR_INTER_FONT_FAMILY = '--cg-inter-font-family';
export const CSS_VAR_IBM_PLEX_MONO_FONT_FAMILY =
  '--cg-ibm-plex-mono-font-family';

export const typographyOptions: TypographyOptions = {
  fontFamily: [
    'Pretendard Variable',
    'Pretendard',
    'Inter',
    FALLBACK_FONT_FAMILIES,
  ].join(','),
  display1: {
    fontFamily: PRETENDARD_FONT_FAMILY,
    fontSize: '40px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '48px',
  },
  h1: {
    fontFamily: PRETENDARD_FONT_FAMILY,
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '30px',
  },
  h2: {
    fontFamily: PRETENDARD_FONT_FAMILY,
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '24px',
  },
  h3: {
    fontFamily: PRETENDARD_FONT_FAMILY,
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '22px',
  },
  h4: {
    fontFamily: PRETENDARD_FONT_FAMILY,
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '20px',
  },
  h5: {
    fontFamily: PRETENDARD_FONT_FAMILY,
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '18px',
  },
  body1: {
    fontFamily: PRETENDARD_FONT_FAMILY,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px',
  },
  body2: {
    fontFamily: PRETENDARD_FONT_FAMILY,
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '18px',
  },
  body3: {
    fontFamily: PRETENDARD_FONT_FAMILY,
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '20px',
  },
  caption: {
    fontFamily: PRETENDARD_FONT_FAMILY,
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
  },
  caption2: {
    fontFamily: PRETENDARD_FONT_FAMILY,
    fontSize: '10px',
    fontWeight: 400,
    lineHeight: '12px',
  },
  button: {
    fontFamily: PRETENDARD_FONT_FAMILY,
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '18px',
  },
  small1: {
    fontFamily: PRETENDARD_FONT_FAMILY,
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '16px',
  },
  small2: {
    fontFamily: PRETENDARD_FONT_FAMILY,
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '12px',
  },
  // NOTE: Inter
  hugeText1: {
    fontFamily: `var(${CSS_VAR_INTER_FONT_FAMILY})`,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '72px',
    lineHeight: '88px',
  },
  largeText1: {
    fontFamily: `var(${CSS_VAR_INTER_FONT_FAMILY})`,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '32px',
    lineHeight: '40px',
  },
  largeText2: {
    fontFamily: `var(${CSS_VAR_INTER_FONT_FAMILY})`,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '30px',
  },
  largeText3: {
    fontFamily: `var(${CSS_VAR_INTER_FONT_FAMILY})`,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '22px',
  },
  mediumText1: {
    fontFamily: `var(${CSS_VAR_INTER_FONT_FAMILY})`,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '18px',
  },
  // NOTE: Subheader
  subhead1: {
    fontFamily: PRETENDARD_FONT_FAMILY,
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '30px',
  },
  subhead2: {
    fontFamily: PRETENDARD_FONT_FAMILY,
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
  },
  subhead3: {
    fontFamily: PRETENDARD_FONT_FAMILY,
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '22px',
  },
  subhead4: {
    fontFamily: PRETENDARD_FONT_FAMILY,
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
  },
  subhead5: {
    fontFamily: PRETENDARD_FONT_FAMILY,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '18px',
  },
  subhead6: {
    fontFamily: PRETENDARD_FONT_FAMILY,
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '16px',
  },
  subhead7: {
    fontFamily: PRETENDARD_FONT_FAMILY,
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '14px',
  },
};

export const appTheme = createTheme({
  typography: typographyOptions,
  palette: {
    primary: {
      main: primaryColors['100'],
      // NOTE: MUI shades
      900: primaryColors['100'],
      800: primaryColors['90'],
      700: primaryColors['80'],
      600: primaryColors['70'],
      500: primaryColors['60'],
      400: primaryColors['50'],
      300: primaryColors['40'],
      200: primaryColors['30'],
      // NOTE: Cigro shades
      100: primaryColors['100'],
      90: primaryColors['90'],
      80: primaryColors['80'],
      70: primaryColors['70'],
      60: primaryColors['60'],
      50: primaryColors['50'],
      40: primaryColors['40'],
      30: primaryColors['30'],
      20: primaryColors['20'],
      10: primaryColors['10'],
      5: primaryColors['5'],
    },
    neutral: {
      main: neutralColors['7'], // TODO: update later
      // NOTE: Cigro shades
      7: neutralColors['7'],
      6: neutralColors['6'],
      5: neutralColors['5'],
      4: neutralColors['4'],
      3: neutralColors['3'],
      2: neutralColors['2'],
      1: neutralColors['1'],
      0: neutralColors['0'],
    },
    neutralV2: {
      main: neutralV2Colors['8'], // TODO: update later
      // NOTE: Cigro shades
      25: neutralV2Colors['25'],
      8: neutralV2Colors['8'],
      7: neutralV2Colors['7'],
      6: neutralV2Colors['6'],
      5: neutralV2Colors['5'],
      4: neutralV2Colors['4'],
      3: neutralV2Colors['3'],
      2: neutralV2Colors['2'],
      1: neutralV2Colors['1'],
      0: neutralV2Colors['0'],
    },
    text: {
      secondary: textColors[0], // TODO: update later
      disabled: textColors[4], // TODO: update later
      primary: textColors.primary,
      darkGreen: textColors.darkGreen,
      darkPink: textColors.darkPink,
      5: textColors[5],
      4: textColors[4],
      3: textColors[3],
      2: textColors[2],
      1: textColors[1],
      0: textColors[0],
    },
    point: {
      warmPink10: pointColors.warmPink10,
      warmPink100: pointColors.warmPink100,
      mediumTurquoise10: pointColors.mediumTurquoise10,
      mediumTurquoise100: pointColors.mediumTurquoise100,
      aquaMarine: pointColors.aquaMarine,
      blueLotus: pointColors.blueLotus,
    },
    chart: {
      blueLotus: chartColors.blueLotus,
      butterflyBlue: chartColors.butterflyBlue,
      blueDiamond: chartColors.blueDiamond,
      lightMustard: chartColors.lightMustard,
      coralPink: chartColors.coralPink,
      brinkPink: chartColors.brinkPink,
      deepFuchsia: chartColors.deepFuchsia,
      purpleFlower: chartColors.purpleFlower,
    },
    semantic: {
      negative100: semanticColors.negative100,
      negative90: semanticColors.negative90,
      negative80: semanticColors.negative80,
      negative50: semanticColors.negative50,
      negative10: semanticColors.negative10,
      negative5: semanticColors.negative5,
      neutral: semanticColors.neutral,
      neutral5: semanticColors.neutral5,
      neutral10: semanticColors.neutral10,
      neutral50: semanticColors.neutral50,
      neutral100: semanticColors.neutral100,
      neutral80: semanticColors.neutral80,
      neutral90: semanticColors.neutral90,
      positive: semanticColors.positive,
      positive5: semanticColors.positive5,
      positive10: semanticColors.positive10,
      positive50: semanticColors.positive50,
      positive80: semanticColors.positive80,
      positive90: semanticColors.positive90,
      positive100: semanticColors.positive100,
      information5: semanticColors.information5,
      information10: semanticColors.information10,
      information20: semanticColors.information20,
      information30: semanticColors.information30,
      information50: semanticColors.information50,
      information60: semanticColors.information60,
      information70: semanticColors.information70,
    },
    background: {
      default: backgroundColors[1],
      paper: backgroundColors[1],
      0: backgroundColors[0],
      1: backgroundColors[1],
    },
  },
  shape: {
    borderRadius: 8,
  },
  shadows: [
    'none',
    '0px 0px 4px 0px rgba(5, 43, 97, 0.12), 2px 6px 12px 0px rgba(0, 0, 0, 0.12)',
    '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
    '0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10)',
    '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
    '0px 24px 48px -12px rgba(16, 24, 40, 0.18)',
    '2px 6px 12px rgba(0, 0, 0, 0.12), 0px 0px 4px rgba(5, 43, 97, 0.12)',
    '2px 6px 12px rgba(0, 0, 0, 0.12), 0px 0px 4px rgba(5, 43, 97, 0.12)',
    '2px 6px 12px rgba(0, 0, 0, 0.12), 0px 0px 4px rgba(5, 43, 97, 0.12)',
    '2px 6px 12px rgba(0, 0, 0, 0.12), 0px 0px 4px rgba(5, 43, 97, 0.12)',
    '2px 6px 12px rgba(0, 0, 0, 0.12), 0px 0px 4px rgba(5, 43, 97, 0.12)',
    '2px 6px 12px rgba(0, 0, 0, 0.12), 0px 0px 4px rgba(5, 43, 97, 0.12)',
    '2px 6px 12px rgba(0, 0, 0, 0.12), 0px 0px 4px rgba(5, 43, 97, 0.12)',
    '2px 6px 12px rgba(0, 0, 0, 0.12), 0px 0px 4px rgba(5, 43, 97, 0.12)',
    '2px 6px 12px rgba(0, 0, 0, 0.12), 0px 0px 4px rgba(5, 43, 97, 0.12)',
    '2px 6px 12px rgba(0, 0, 0, 0.12), 0px 0px 4px rgba(5, 43, 97, 0.12)',
    '2px 6px 12px rgba(0, 0, 0, 0.12), 0px 0px 4px rgba(5, 43, 97, 0.12)',
    '2px 6px 12px rgba(0, 0, 0, 0.12), 0px 0px 4px rgba(5, 43, 97, 0.12)',
    '2px 6px 12px rgba(0, 0, 0, 0.12), 0px 0px 4px rgba(5, 43, 97, 0.12)',
    '2px 6px 12px rgba(0, 0, 0, 0.12), 0px 0px 4px rgba(5, 43, 97, 0.12)',
    '2px 6px 12px rgba(0, 0, 0, 0.12), 0px 0px 4px rgba(5, 43, 97, 0.12)',
    '2px 6px 12px rgba(0, 0, 0, 0.12), 0px 0px 4px rgba(5, 43, 97, 0.12)',
    '2px 6px 12px rgba(0, 0, 0, 0.12), 0px 0px 4px rgba(5, 43, 97, 0.12)',
    '2px 6px 12px rgba(0, 0, 0, 0.12), 0px 0px 4px rgba(5, 43, 97, 0.12)',
    '2px 6px 12px rgba(0, 0, 0, 0.12), 0px 0px 4px rgba(5, 43, 97, 0.12)',
  ],
  components: {
    ...PopoverTheme,
    ...InputTextFieldTheme,
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: neutralColors[5],
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        color: 'text.0',
      },
    },
    MuiSkeleton: {
      defaultProps: {
        animation: 'wave',
        sx: {
          bgcolor: 'background.0',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        arrow: {
          color: 'var(--tooltip-neutral-090, rgba(32, 32, 32, 0.90))',
        },
        tooltipPlacementBottom: {
          [`&.${tooltipClasses.tooltipPlacementBottom}.${tooltipClasses.tooltipArrow}`]:
            {
              marginTop: '9px', // NOTE: 5px + 4px
            },
        },
        tooltipPlacementRight: {
          [`&.${tooltipClasses.tooltipPlacementRight}.${tooltipClasses.tooltipArrow}`]:
            {
              marginLeft: '9px', // NOTE: 5px + 4px
            },
          [`&.${tooltipClasses.tooltipArrow} .${tooltipClasses.arrow}`]: {
            marginLeft: '-0.7em',
          },
        },
        tooltip: {
          backgroundColor: 'var(--tooltip-neutral-090, rgba(32, 32, 32, 0.90))',
          color: textColors[5],
          padding: '6px 8px',
          borderRadius: '4px',
          ...typographyOptions['caption'],
        },
      },
    },
  },
});
