import type { RequestManager } from '../__base';

import {
  AxiosHeaders,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
  AxiosResponseHeaders,
} from 'axios';
import invariant from 'tiny-invariant';

import { ApiError } from '@/api-error';
import { zApiServerErrorSchema } from '@/models/common';

const baseURL = process.env.API;

const apiVersion = '/v1';

export function createFetchRequestManager(init?: RequestInit): RequestManager {
  return {
    async get<T, R>(
      url: string,
      config: AxiosRequestConfig<R>,
    ): Promise<AxiosResponse<T, R>> {
      const method = 'GET';
      const axiosHeaders = config?.headers;
      const headers: Record<string, string> = {};
      if (axiosHeaders instanceof AxiosHeaders) {
        const axiosHeadersObject = axiosHeaders.toJSON();
        Object.entries(axiosHeadersObject).forEach(([key, value]) => {
          if (value !== null) {
            headers[key] = value.toString();
          }
        });
      }

      const params = config?.params;

      const finalUrl = new URL(apiVersion + url, baseURL);

      if (params) {
        finalUrl.search = new URLSearchParams(params).toString();
      }

      const urlString = finalUrl.toString();

      const fetchOptions: RequestInit = {
        method,
        headers,
        signal: (config?.signal as AbortSignal) ?? null,
        ...init,
      };

      const rawResponse = await fetch(urlString, fetchOptions);

      const resHeader = new AxiosHeaders();

      rawResponse.headers.forEach((value, key) => {
        resHeader.set(key, value);
      });

      const responseData = (await rawResponse.json()) as T;

      if (!rawResponse.ok) {
        const zApiError = zApiServerErrorSchema.safeParse(responseData);
        if (zApiError.success)
          throw new ApiError(responseData, zApiError.data, rawResponse.status);
        console.error(responseData);
        throw new Error('Failed to fetch');
      }

      return {
        data: responseData,
        status: rawResponse.status,
        statusText: rawResponse.statusText,
        headers: resHeader as AxiosResponseHeaders,
        config: {
          ...config,
          headers: config?.headers as AxiosRequestHeaders,
        },
      };
    },
    async request<T, R>(
      config: AxiosRequestConfig<R>,
    ): Promise<AxiosResponse<T, R>> {
      invariant(baseURL, 'no base url provided');
      const url = config.url;
      invariant(url, 'no  url provided');
      const method = config.method;
      const axiosHeaders = config?.headers;
      const headers: Record<string, string> = {};
      if (axiosHeaders instanceof AxiosHeaders) {
        const axiosHeadersObject = axiosHeaders.toJSON();
        Object.entries(axiosHeadersObject).forEach(([key, value]) => {
          if (value !== null) {
            headers[key] = value.toString();
          }
        });
      }

      const data = config.data;

      const params = config.params;

      const finalUrl = new URL(apiVersion + url, baseURL);

      if (params) {
        finalUrl.search = new URLSearchParams(params).toString();
      }

      const urlString = finalUrl.toString();

      const fetchOptions: RequestInit = {
        method,
        headers,
        signal: (config?.signal as AbortSignal) ?? null,
        ...init,
      };

      if (data) {
        fetchOptions.body = JSON.stringify(data);
      }

      const rawResponse = await fetch(urlString, fetchOptions);
      const resHeader = new AxiosHeaders();
      rawResponse.headers.forEach((value, key) => {
        resHeader.set(key, value);
      });

      const responseData = (await rawResponse.json()) as T;

      if (!rawResponse.ok) {
        const zApiError = zApiServerErrorSchema.safeParse(responseData);
        if (zApiError.success)
          throw new ApiError(responseData, zApiError.data, rawResponse.status);
        console.error(responseData);
        throw new Error('Failed to fetch');
      }

      return {
        data: responseData,
        status: rawResponse.status,
        statusText: rawResponse.statusText,
        headers: resHeader as AxiosResponseHeaders,
        config: {
          ...config,
          headers: config?.headers as AxiosRequestHeaders,
        },
      };
    },
  };
}
