export const sessionKey = (email: string | null | undefined) => {
  if (!email) return;
  try {
    const encodedEmail = encodeURIComponent(email);
    return `__endash_session__${encodedEmail}`;
  } catch (error) {
    console.error('Error generating session key', error);
  }
};

export const userLangKey = (email: string| null | undefined) => {
  if (!email) return;
  try {
    const encodedEmail = encodeURIComponent(email);
    return `__endash_lang__${encodedEmail}`;
  } catch (error) {
    console.error('Error generating lang key', error);
  }
};
