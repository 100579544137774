'use client';

import { useParams } from 'next/navigation';
import { useRouter } from 'next/router';
import Script from 'next/script';

import { useSession } from 'next-auth/react';

import appConfig from '@/constants/configs';
import { useGlobalMe } from '@/hooks/use-global-me';
import { useTeamsQuery } from '@/layouts/navbar/use-team';

type Props = {};

export const GoogleAnalyticsScripts = (props: Props) => {
  const { query, isReady } = useRouter();

  const { me, isLoading } = useGlobalMe();

  const { data: session } = useSession();

  const { data: teams, isInitialLoading: isTeamLoading } = useTeamsQuery({
    enabled: session != null && session?.error == null,
  });

  if (isLoading || isTeamLoading) return null;

  if (!me || !teams) return null;

  if (!isReady) return null;

  const { team_id } = query;

  const activeTeam = teams.find((team) => team.id === team_id);

  if (activeTeam?.company_id) {
    return (
      <>
        <Script
          async
          strategy="afterInteractive"
          src={`https://www.googletagmanager.com/gtag/js?id=${appConfig.GOOGLE_ANALYTIC_ID}`}
        />
        <Script id="gtm" strategy="afterInteractive">
          {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('set', 'user_id', '${me.id}');
          gtag('set', 'user_properties', {
            email: '${me.email}',
            company_id: ${
              activeTeam.company_id ? `'${activeTeam.company_id}'` : 'null'
            },
            company_name: ${me.company_name ? `'${me.company_name}'` : 'null'},
            phone_number: ${me.phone_number ? `'${me.phone_number}'` : 'null'}
          });
          gtag('config', '${appConfig.GOOGLE_ANALYTIC_ID}');`}
        </Script>
      </>
    );
  }

  return (
    <>
      <Script
        async
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${appConfig.GOOGLE_ANALYTIC_ID}`}
      />
      <Script id="gtm" strategy="afterInteractive">
        {`window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('set', 'user_id', '${me.id}');
        gtag('set', 'user_properties', {
          email: '${me.email}',
          company_name: ${me.company_name ? `'${me.company_name}'` : 'null'},
          phone_number: ${me.phone_number ? `'${me.phone_number}'` : 'null'}
        });
        gtag('config', '${appConfig.GOOGLE_ANALYTIC_ID}');`}
      </Script>
    </>
  );
};

export const GoogleAnalyticsScripts__App = (props: Props) => {
  const params = useParams();

  const { me, isLoading } = useGlobalMe();

  const { data: session } = useSession();

  const { data: teams, isInitialLoading: isTeamLoading } = useTeamsQuery({
    enabled: session != null && session?.error == null,
  });

  if (isLoading || isTeamLoading) return null;

  if (!me || !teams) return null;

  const activeTeam = teams.find((team) => team.id === params?.['ids']?.[0]);

  if (activeTeam?.company_id) {
    return (
      <>
        <Script
          async
          strategy="afterInteractive"
          src={`https://www.googletagmanager.com/gtag/js?id=${appConfig.GOOGLE_ANALYTIC_ID}`}
        />
        <Script id="gtm" strategy="afterInteractive">
          {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('set', 'user_id', '${me.id}');
          gtag('set', 'user_properties', {
            email: '${me.email}',
            company_id: ${
              activeTeam.company_id ? `'${activeTeam.company_id}'` : 'null'
            },
            company_name: ${me.company_name ? `'${me.company_name}'` : 'null'},
            phone_number: ${me.phone_number ? `'${me.phone_number}'` : 'null'}
          });
          gtag('config', '${appConfig.GOOGLE_ANALYTIC_ID}');`}
        </Script>
      </>
    );
  }

  return (
    <>
      <Script
        async
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${appConfig.GOOGLE_ANALYTIC_ID}`}
      />
      <Script id="gtm" strategy="afterInteractive">
        {`window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('set', 'user_id', '${me.id}');
        gtag('set', 'user_properties', {
          email: '${me.email}',
          company_name: ${me.company_name ? `'${me.company_name}'` : 'null'},
          phone_number: ${me.phone_number ? `'${me.phone_number}'` : 'null'}
        });
        gtag('config', '${appConfig.GOOGLE_ANALYTIC_ID}');`}
      </Script>
    </>
  );
};

export const GoogleAnalyticsScriptsAnonymous__App = (props: Props) => {
  if (!appConfig.GOOGLE_ANALYTIC_ID) return null;
  return (
    <>
      <Script
        async
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${appConfig.GOOGLE_ANALYTIC_ID}`}
      />
      <Script id="gtm" strategy="afterInteractive">
        {`window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${appConfig.GOOGLE_ANALYTIC_ID}');`}
      </Script>
    </>
  );
};
