import type { CreateAxiosDefaults } from 'axios';

import axios from 'axios';

import {
  getSessionMemoized,
  registerApiErrorParserResponseInterceptor,
  registerRefreshTokenOn401ResponseInterceptor,
} from '@/adapters/api/axios/utils';
import { TokenStorageObject } from '@/utils/client-side-token';

// NOTE: set 70 secs due to BIG QUERY sometime runs longer than 30s
const TIMEOUT_MS = 1000 * 70;

const AXIOS_CONFIG: CreateAxiosDefaults = {
  baseURL: process.env.API,
  timeout: TIMEOUT_MS,
  withCredentials: false,
};

const axiosInstance = axios.create(AXIOS_CONFIG);

const refreshAuthFn = async (
  failedJwt: string,
): Promise<TokenStorageObject> => {
  const maybeNewSession = await getSessionMemoized(failedJwt);
  return {
    jwt: maybeNewSession?.access_token ?? null,
    access_token_expires_at: maybeNewSession?.access_token_expires_at ?? null,
    refreshToken: async () => null,
  };
};

// NOTE!: the order of interceptor IS IMPORTANT!
// 1
// registerTokenHeaderRequestInterceptor(axiosInstance, tokenGetter);
// 2
registerRefreshTokenOn401ResponseInterceptor(axiosInstance, refreshAuthFn);
// 3
registerApiErrorParserResponseInterceptor(axiosInstance);

export default axiosInstance;
